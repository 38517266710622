import React, { FC, FormEvent } from 'react';
import { navigate, PageProps } from 'gatsby';
import { Col, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/Index.scss';
import classes from './index.module.scss';

import {
  API_URL,
  ContactUsCaption,
  IndexPageCaption,
  OtherLinks,
  SeoCaption,
} from '../constants';
import Header from '../components/Header/Header';
import Layout from '../components/Layout/Layout';
import Hero, { HeroType } from '../components/Hero/Hero';
import SEO from '../components/SEO/SEO';
import { useState } from 'react';
import axios from 'axios';
import CustomModal from '../components/CustomModal/CustomModal';
import ImageSuccess from '../images/success.png';
import { prohibitedEmails } from '../utils/prohibitedEmail';

const Home: FC<PageProps> = (props) => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const subscribe = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData();
    data.append('email', emailValue);
    try {
      const response = await axios.post(`${API_URL}/subscribe`, data);

      if (response.status !== 200) {
        setShowModal(true);
      } else {
        setSuccess(true);
      }
    } catch (e) {
      setShowModal(true);
    }
  };

  const [emailValidated, setEmailValidated] = useState(false);
  const [blurred, setBlurred] = useState(false);

  const checkEmailValidity = (value: string) => {
    const pattern = prohibitedEmails.find((email) => value.includes(email));
    if (pattern) setEmailValidated(false);
    else setEmailValidated(true);
  };

  return (
    <Layout>
      <SEO title={SeoCaption.HOME} />
      <Header
        page={HeroType.HOME}
        {...props}
        title={IndexPageCaption.HEADER_TITLE}
        subtitle={IndexPageCaption.HEADER_SUBTITLE}
        description={IndexPageCaption.HEADER_DESCRIPTION}
        descriptionRedirect={OtherLinks.ABOUT}
      />
      <Row className={classes.ContentContainer}>
        <Col sm={12} md={12} lg={5} xl={5} className={classes.LeftColumn}>
          <div className={classes.ContentTitle}>
            {IndexPageCaption.FIRST_SECTION_TITLE}
          </div>
          <div className={classes.ContentSubTitle}>
            {IndexPageCaption.FIRST_SECTION_SUBTITLE_1}
          </div>
          <div className={classes.ContentDivider} />
          <div className={classes.ContentSubTitle}>
            {IndexPageCaption.FIRST_SECTION_SUBTITLE_2}
          </div>
          <div
            className={classes.ContentButton}
            onClick={() => navigate(OtherLinks.SERVICES)}
          >
            {IndexPageCaption.FIRST_SECTION_DESCRIPTION}
          </div>
        </Col>
        <Col md={1} />
        <Col sm={12} md={12} lg={6} xl={6} className={classes.RightColumn}>
          <div className={classes.HandshakeImg} />
        </Col>
      </Row>
      <div className={classes.ContentContainerSecondSection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <Row className={classes.GreyContainer}>
          <Col sm={12} md={12} lg={5} xl={5} className={classes.LeftGreyColumn}>
            {IndexPageCaption.SECOND_SECTION_TITLE}
          </Col>
          <Col md={1} />
          <Col
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className={classes.RightGreyColumn}
          >
            <div className={classes.ContentSubTitle}>
              {IndexPageCaption.SECOND_SECTION_SUBTITLE}
            </div>
            <form onSubmit={subscribe}>
              <div className={classes.EmailInput}>
                <input
                  type="email"
                  name="email"
                  required={true}
                  value={emailValue}
                  onChange={(event) => {
                    setEmailValue(event.target.value);
                    checkEmailValidity(event.target.value);
                  }}
                  onFocus={() => setBlurred(false)}
                  onBlur={() => setBlurred(true)}
                  placeholder={
                    IndexPageCaption.SECOND_SECTION_INPUT_PLACEHOLDER
                  }
                />

                <label className={classes.Error}>
                  {!emailValidated && blurred && IndexPageCaption.EMAIL_ERROR}
                </label>
              </div>
              <div className={classes.ContentButtonContainer}>
                <button
                  className={classes.ContentButton}
                  type={'submit'}
                  disabled={!emailValidated}
                >
                  {IndexPageCaption.SECOND_SECTION_DESCRIPTION}
                </button>
                {success && (
                  <div className={classes.Success}>
                    <img src={ImageSuccess} alt={'Success'} />
                    {IndexPageCaption.SUBSCRIPTION_SUCCESS}
                  </div>
                )}
              </div>
            </form>
          </Col>
          <CustomModal
            shouldShow={showModal}
            click={() => setShowModal(false)}
            bodyText={ContactUsCaption.MODAL_BODY}
            buttonText={ContactUsCaption.MODAL_BUTTON}
          />
        </Row>
      </div>
    </Layout>
  );
};

export default Home;
